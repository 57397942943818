import * as React from "react";
import { useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";

const Updates = styled.ul`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  list-style-type: none;
`;
const Update = styled.li`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1rem;
  border: 1px solid var(--color-text);
  div {
    margin: 0.25rem;
    padding: 0.75rem;
  }
`;
const Label = styled.div`
  font-weight: bold;
`;

const Pill = styled.div`
  border-radius: 15px;
`;

const Available = styled(Pill)`
  background-color: var(--color-success);
  color: var(--color-background);
`;

const Unavailable = styled(Pill)`
  background-color: var(--color-error);
  color: var(--color-background);
`;

const Log = ({ data }) => {
  console.log(2, data);
  //const data = node.data.node.data
  const isAvailable = (key) => {
    const available = key in data && data[key] === true;
    return (
      <div>
        <Label>{key}</Label>
        {available ? (
          <Available>{key} Available</Available>
        ) : (
          <Unavailable>{key} Unavailable</Unavailable>
        )}
      </div>
    );
  };

  const keys = ["Internet", "Power"];
  const timestamp = new Date(data.Timestamp);
  return (
    <Update>
      <div>
        <Label>Time</Label>
        <div>
          {timestamp.toLocaleDateString()} {timestamp.toLocaleTimeString()}
        </div>
      </div>
      {keys.map((key) => isAvailable(key))}
      <div>
        <Label>Send method</Label>
        <div>
          {"SMS" in data && data.SMS !== null && data.SMS === true
            ? "SMS"
            : "Internet"}
        </div>
      </div>
      <div>
        <Label>Note</Label>
        <div>{data.Notes}</div>
      </div>
    </Update>
  );
};

const Hurricane = ({ data }) => {
  const latestId = data.allAirtable.edges[0].node.data.ID;
  const [nodes, setNodes] = useState(
    data.allAirtable.edges.map((x) => x.node.data)
  );

  useEffect(() => {
    const url =
      "https://api.airtable.com/v0/appUH2hjprVOUHRqf/Updates?" +
      new URLSearchParams({
        filterByFormula: `ID > ${latestId}`,
      });

    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${process.env.GATSBY_AIRTABLE_API_KEY}`,
          },
        });
        const json = await response.json();
        const result = json.records.map((x) => x.fields);
        setNodes([...result, ...nodes]);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Layout pageTitle="Status Updates">
      <div>
        <h2>Status updates during **insert hurricane name here**</h2>
        <p>Automatic updates are sent every 3 hours (8, 11, 2, 5, etc.)</p>
        <p>
          In case of loss of power & internet, updates will be sent via SMS on
          an ad hoc basis.
        </p>
        <p>All times are EST.</p>
      </div>
      <Updates>
        {nodes
          .sort((a, b) => a.Timestamp < b.Timestamp)
          .map((x) => (
            <Log key={x.ID} data={x} />
          ))}
      </Updates>
    </Layout>
  );
};

export const query = graphql`
  query {
    allAirtable(
      filter: { table: { eq: "Updates" } }
      sort: { fields: data___Timestamp, order: DESC }
    ) {
      edges {
        node {
          data {
            ID
            Notes
            Power
            Internet
            SMS
            Timestamp
          }
        }
      }
    }
  }
`;

export default Hurricane;
